
<template>
  <div class="deposit-content-item-wrapper">
    <el-dialog
      width="500px"
      :title="title"
      :visible.sync="dialogVisible"
      @open="open"
      @closed="closed"
    >
      <div v-if="contentShow"  class="eagleeyeUsage-dialog">
        <div class="content">
          <p>姓名:</p> <p>{{cotentItem.userName}}</p>
        </div>
        <div class="content">
          <p>身份:</p> <p>{{userType}}</p>
        </div>
        <div class="content">
          <p>性别:</p> <p>{{cotentItem.sex}}</p>
        </div>
        <div class="content">
          <p>手机:</p> <p>{{cotentItem.phoneNum}}</p>
        </div>
        <div class="content">
          <p>房号:</p> <p>{{address}}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { _userType } from './map'
import { Row, Col } from 'element-ui'
Vue.use(Row)
Vue.use(Col)

export default {
  name: 'deposit-content-item',
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      // `false`
      default: false
    },
    // 标题
    title: {
      type: String,
      // `账号信息`
      default: '账号信息'
    },
    // 要显示的物品内容的item
    cotentItem: {
      type: Object,
      // `[]`
      default: () => {
        return {}
      }
    }
  },

  watch: {
    visible (newValue) {
      this.dialogVisible = newValue
    },

    dialogVisible (newValue) {
      this.$emit('update:visible', newValue)
    }
  },

  data () {
    return {
      dialogVisible: this.visible,
      showImageList: [],
      contentShow: false,
      userType: '',
      address: ''
    }
  },
  methods: {
    // Dialog 打开的回调
    open () {
      this.contentShow = true
      if (this.cotentItem.groupType === '物管') {
        this.userType = '物管'
      } else {
        this.userType = _userType[this.cotentItem.userType] ? _userType[this.cotentItem.userType] : '身份未录入'
      }
      if (this.cotentItem.groupType === '物管') {
        this.address = '生活服务中心'
      } else {
        this.address = this.cotentItem.address ? this.cotentItem.address : '无'
      }
    },

    // Dialog 关闭动画结束时的回调
    closed () {
      this.contentShow = false
    }
  }
}
</script>

<style>
</style>

<style scoped  lang="scss">
.eagleeyeUsage-dialog {
  margin-left: 10px;
  .content{
    display: flex;
    p:nth-child(1) {
      margin-right: 15px;
    }
  }
}
</style>
