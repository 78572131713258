<template>
  <div class="eagleeyeUsageList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :handleData="handleData"
      :isMultiSelect="true"
    >
      <template #searchSlot>
        <v-input label="关联账号" v-model="searchParams.userName"></v-input>
        <v-input label="鹰眼组名称" v-model="searchParams.groupName"></v-input>
        <v-select label="使用者类型" v-model="searchParams.groupType" :options="groupTypeOps"></v-select>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-datepicker label="创建时间段" :maxDate="maxDate" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate"></v-datepicker>
        <v-datepicker label="审核时间段" :maxDate="maxDate" :startTime.sync="searchParams.auditStartDate" :endTime.sync="searchParams.auditEndDate"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量通过" permission="update" @click="batchHandle(scope.selectedData, 2)"></v-button>
        <v-button text="批量拒绝" permission="update" @click="batchHandle(scope.selectedData, 3)"></v-button>
        <v-button text="批量关闭" permission="update" @click="batchHandle(scope.selectedData, 4)"></v-button>
      </template>
       <eag-dialog
        :cotent-item="selectItem"
        :visible.sync="detailShow"
      >
      </eag-dialog>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, dealOnBatchUrl } from './api'
import { groupTypeOps, searchStatusOps } from './map'
import eagDialog from './EagleeyeUsageDialog'
import { communityParams } from 'common/select2Params'

export default {
  name: 'EagleeyeUsageList',
  components: { eagDialog },
  data () {
    let _this = this
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      newUsageInfoVisible: true,
      groupTypeOps: groupTypeOps,
      statusOps: searchStatusOps,
      maxDate: new Date(),
      communityParams,
      dealOnBatchUrl: dealOnBatchUrl,
      searchParams: {
        userName: '',
        groupName: '',
        groupType: undefined,
        status: undefined,
        communityId: '',
        startDate: '',
        endDate: '',
        auditStartDate: '',
        auditEndDate: ''
      },
      selectItem: {},
      detailShow: false,
      headers: [
        {
          prop: 'newUsageInfo',
          label: '关联账号',
          formatter (row) {
            return _this.$createElement('span', {
              domProps: {
                innerHTML: `${row.userName}<br/>${row.phoneNum}`
              },
              style: {
                color: '#1b8cf2',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  _this.showDetailContent(row)
                }
              }
            })
          }
        },
        {
          prop: 'groupType',
          label: '使用者类型'
        },
        {
          prop: 'groupName',
          label: '鹰眼组名称'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'updateTime',
          label: '审核时间'
        },
        {
          prop: 'usedCount',
          label: '使用次数',
          align: 'right'
        },
        {
          prop: 'status',
          label: '状态'
        }
      ]
    }
  },
  methods: {
    showDetailContent (row) {
      this.selectItem = row
      this.detailShow = true
    },
    handleData: function (item) {
      if (item.groupType === '用户' && !item.address) {
        item.status = '已搬离'
        item.usedCount = ''
      }
      if (item.status === '待审核') {
        item.updateTime = ''
      }
    },
    // status 1通过 3拒绝 4关闭
    batchHandle (selected, status) {
      let ids = selected.ids
      let userIds = selected.data.map(item => item.userId)
      let names = selected.data.map(item => item.groupName)
      if (ids.length) {
        let postData = {
          ids: ids.join(','),
          userIds: userIds.join(','),
          names: names.join(',')
        }
        let _this = this
        this.$confirm('确定执行该操作？', {
          title: '提示'
        }).then(confirm => {
          // 通过直接进行请求
          if (confirm) {
            _this.handleRequest(postData, status)
          }
        })
      } else {
        this.$message({
          type: 'error',
          message: '请先选择要操作的数据',
          center: true
        })
      }
    },

    // 操作的请求
    handleRequest (postData, status) {
      let _this = this
      this.$axios.post(this.dealOnBatchUrl + `?status=` + status, postData).then(res => {
        let status = String(res.status)
        if (status === '100') {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    edit (row) {
      this.$router.push({
        name: 'eagleeyeUsageForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
<style scoped>
  .eagleeyeUsageList-wrapper >>> a.js-eagleeye-usage {
    color: #1b8cf2;
    cursor:pointer
  }

</style>
